<template>
	<div class="notification" style="z-index: 1000">
		<b class="has-text-weight-bold">
			THIS WEBSITE USES COOKIES
		</b>
		We use cookies to personalise content and to analyse our traffic. 
		<button class="button is-info is-small is-outlined is-pulled-right"
			@click.prevent="accept_cookies()">Accept</button> 
	</div>
</template>

<script>
	export default{
		methods: {
			accept_cookies() {
				localStorage.cookies = true
				setTimeout(() => {
					window.location.reload()
				}, 100)
			}
		},	
	}
</script>

<style scoped>

</style>

